import Cookies from 'js-cookie'

/**
 * Class to manage cookies agreement
 */
export class CookiesController {
  cookiesSaved: boolean
  cookies: Map<string, boolean>

  constructor() {
    this.cookiesSaved = false
    this.cookies = new Map()
    this.getCookiesSavedInfo()

    this.fireActionEvent()

    window.addEventListener('load', () => {
      this.firePopupEvent()
    })
  }

  /**
   * Sets all cookies as not accepted and saves cookies info in localstorage
   */
  disallowAll(): void {
    for (const id in this.cookies) {
      this.cookies.set(id, id.includes('necessary'))
    }

    this.saveCookiesInfo()
  }

  /**
   * Fires event that mean cookies based scripts should take action
   */
  fireActionEvent(): void {
    window.dispatchEvent(new CustomEvent(CookiesEvents.ACTION))
  }

  /**
   * Fires event that mean popup component should take action
   */
  firePopupEvent(): void {
    window.dispatchEvent(new CustomEvent(CookiesEvents.POPUP))
  }

  /**
   * Checks if info about all cookies in popup is saved
   *
   * @returns {boolean} true if info about all cookies is saved, else false
   */
  allCookiesIncludes(): boolean {
    const cookiesSettings = document.querySelector(
      '[data-item="bw-cookies-settings"]',
    )
    let allCookiesIncluded = true

    if (cookiesSettings) {
      cookiesSettings.querySelectorAll('[data-cookie]').forEach((cookie) => {
        if (!this.cookies.has(cookie.getAttribute('data-cookie') as string)) {
          allCookiesIncluded = false
        }
      })
    }

    return allCookiesIncluded
  }

  /**
   * Gets saved cookies info from localstorage
   */
  getCookiesSavedInfo(): void {
    const acceptedCookies = localStorage.getItem('bw-accepted-cookies')

    if (acceptedCookies) {
      const jsonObj = JSON.parse(acceptedCookies)

      if (jsonObj) {
        for (const key in jsonObj) {
          this.cookies.set(key, jsonObj[key])
        }

        this.cookiesSaved = true
      } else {
        this.cookies = new Map()
      }
    } else {
      this.cookies = new Map()
    }
  }

  /**
   * Saves cookies info in localstorage
   */
  saveCookiesInfo(): void {
    const jsonObj: any = {}

    this.cookies.forEach((val, key) => {
      jsonObj[key] = val
    })

    localStorage.setItem('bw-accepted-cookies', JSON.stringify(jsonObj))
    this.cookiesSaved = true
  }

  /**
   * @param {string} cookieId
   * @returns {boolean} true if it is accept for cookie or if accept for cookie is undefined, else false
   */
  isCookieAccepted(cookieId: string): boolean {
    return this.cookies.has(cookieId)
      ? (this.cookies.get(cookieId) as boolean)
      : false // || this.cookies[cookieId] === undefined;
  }

  /**
   * Adds cookie, saves cookies info and fires bw-cookies-action event
   *
   * @param {string} cookieId cookie id
   * @param {boolean} accepted is cookie accepted
   */
  addCookie(cookieId: string, accepted: boolean): void {
    this.cookies.set(cookieId, accepted)
    this.saveCookiesInfo()
    this.fireActionEvent()
  }

  /**
   * Adds cookie and fires bw-cookies-action event
   *
   * @param {string} cookieId cookie id
   * @param {boolean} accepted is cookie accepted
   */
  addCookieWithoutSaveing(cookieId: string, accepted: boolean): void {
    this.cookies.set(cookieId, accepted)
    this.fireActionEvent()
  }

  /**
   * Adds multiple cookies
   * @param {Array<{id: string, accepted: boolean}>} cookiesStatus Array of cookies status id - cookie id, accepted - is cookie accepted
   */
  addCookies(cookiesStatus: Map<string, boolean>): void {
    cookiesStatus.forEach((value, key) => {
      this.addCookie(key, value)
    })
  }
}

/**
 * Base class for cookies popup
 */
export abstract class CookiesPopup {
  cookiesSettingsBox: Element
  /**
   * Set base popup container and start method which add event listeners.
   * @param {Element} cookiesBox  base popup container.
   */
  constructor(cookiesBox: Element) {
    this.cookiesSettingsBox = cookiesBox

    if (this.isNewCookie()) {
      this.addEventHandlers()
    } else {
      const btnReopen = document.querySelector(
        '[data-cookies-btn-reopen="reopen"]',
      )

      if (btnReopen) {
        btnReopen.classList.remove('hidden')
      }
    }
  }

  abstract addEventHandlers(): void

  /**
   * Checks that all cookies are saved and there no new cookies.
   * If any new cookie show popup.
   *
   * @returns {boolean} true if new cookies.
   */
  abstract isNewCookie(): boolean

  abstract reopen(): void

  /**
   * Clears localstorage and cookies which don't have 'bw' in name.
   */
  clearStorage(): void {
    const cookies = document.cookie.split(';').map((cookie) => {
      const splited = cookie.split('=')

      if (splited.length > 0) return splited[0]
    })

    cookies.forEach((cookieName) => {
      if (cookieName && cookieName.search('bw') === -1) {
        Cookies.remove(cookieName.trim())
      }
    })

    Object.keys(localStorage).forEach((key) => {
      if (key.search('bw') === -1) {
        localStorage.removeItem(key)
      }
    })
  }
}

export enum CookiesEvents {
  ACTION = 'bw-cookies-action',
  POPUP = 'bw-cookies-popup',
}

// export default new CookiesController();
